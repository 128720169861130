// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

function autoHeight() {
    if ( document.body.clientHeight < window.innerHeight ) {
        document.querySelector('footer').style.position = 'absolute';
        document.querySelector('footer').style.bottom = '0';
        document.querySelector('footer').style.width = '100%';
    }
}

document.addEventListener("DOMContentLoaded", function() {
    setTimeout(autoHeight, 1000);
});